* {
	box-sizing: border-box;
}

html,
body {
	max-width: 100vw;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
	font-family: "Roboto", sans-serif;
}

.display-error-page {
	padding: 14vh 15px;
	text-align: center;
}
.display-error-page img {
	width: 100%;
	max-width: 400px;
}
.display-error-page h1 {
	font-size: 38px;
	color: rgba(70, 70, 68, 1);
	font-weight: 500;
	margin-bottom: 15px;
}
.display-error-page h2 {
	font-size: 52px;
	color: rgba(234, 51, 143, 1);
	font-weight: 600;
	margin: 0;
	padding: 0;
}
.display-error-page i {
	font-size: 16px;
	display: block;
	font-weight: 400;
	margin: 15px 0 0 0;
	padding: 0;
	color: rgba(70, 70, 68, 1);
}

.drawer-header {
	height: 64px;
	padding: 8px 4px;
	background: rgb(0, 188, 212);
}

.drawer-header svg {
	fill: #fff !important;
}

.logout-button {
	position: absolute !important;
	bottom: 8px !important;
	left: 0 !important;
	right: 0 !important;
}

.contribution-grid {
}

.contribution-image-wrapper {
	width: 20%;
	height: 0;
	padding-bottom: 20%;
	position: relative;
	overflow: visible !important;
	display: inline-block;
	margin-top: -4px;
}

.contribution-image {
	width: 100%;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	cursor: pointer;
	border: 2px solid #fff;
	background-color: #dadada;
	position: absolute;
	top: 0;
	left: 0;
}
.contribution-image img {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	object-fit: contain;
}

.collection-published {
	width: calc(100% - 4px);
	position: absolute;
	z-index: 8;
	right: 0;
	top: 2px;
	left: 2px;
	padding: 12px 12px 65px 12px;
	background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
	display: flex;
	justify-content: flex-end;
	pointer-events: none;
}
.collection-published svg {
	width: 15px;
	height: 15px;
	fill: #fff;
}

.collection-settings {
	font-size: 1rem !important;
	z-index: 10;
}
.contribution-image.collection-image {
	padding-top: 100%;
	background-size: cover;
	background-position: center;
}

.contribution-image.collection-image:not(.collection-hide-title):before {
	width: 100%;
	content: attr(title);
	position: absolute;
	bottom: 0;
	left: 0;
	padding: 54px 15px 15px 15px;
	background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
	color: #ffffff;
	text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.4);
	font-size: 18px;
	box-sizing: border-box;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.contribution-image-fullscreen-button {
	position: absolute !important;
	top: 8px;
	right: 8px;
	display: none !important;
}

.contribution-image-wrapper:hover .contribution-image-fullscreen-button {
	display: block !important;
}

.contribution-image.selected:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	background: rgba(255, 64, 129, 0.6);
	display: block;
	content: "";
}

.grid-list-container {
	overflow: hidden;
	position: relative;
	-moz-transition: padding-right 218ms cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: padding-right 218ms cubic-bezier(0.4, 0, 0.2, 1);
	-webkit-transition: padding-right 218ms cubic-bezier(0.4, 0, 0.2, 1);
	transition: padding-right 218ms cubic-bezier(0.4, 0, 0.2, 1);
	padding-right: 0px !important;
}

.grid-list-content-wrapper {
	position: relative;
}

.grid-list-container.collapsed {
	padding-right: 280px !important;
}

.contribution-details-pane {
	position: absolute !important;
	top: 0;
	width: 280px;
	background: #fff;
	-moz-transition: right 218ms cubic-bezier(0.4, 0, 0.2, 1);
	-o-transition: right 218ms cubic-bezier(0.4, 0, 0.2, 1);
	-webkit-transition: right 218ms cubic-bezier(0.4, 0, 0.2, 1);
	transition: right 218ms cubic-bezier(0.4, 0, 0.2, 1);
	right: -280px;
	overflow: auto;
	border-left: 1px solid #ccc;
	min-height: calc(100vh - 120px);
	height: 100%;
}

.contribution-details-pane.collapsed {
	right: 0;
}

.contribution-details-pane.sticky {
	position: fixed !important;
}

.no-text-clamp {
	text-overflow: initial !important;
	height: auto !important;
	white-space: initial !important;
}

.actions-list {
	opacity: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-content: space-around;
	justify-content: center;
	background: rgba(0, 0, 0, 0.4);
}

.actions-list:hover {
	opacity: 1;
}

.actions-list > div {
	margin: 10px !important;
}

.left-arrow,
.right-arrow {
	position: absolute !important;
	top: 50%;
	transform: translateY(-50%);
	z-index: 999;
}

.left-arrow {
	left: 20px;
}

.right-arrow {
	right: 20px;
}

.pagination {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	justify-content: center;
}

.pagination .page-number {
	line-height: 24px;
	padding: 12px;
}

.loader {
	padding: 96px;
	text-align: center;
}

.list-selects {
	margin: 0 16px;
}

.check-images {
	position: absolute;
	right: 6px;
	bottom: 6.5px;
}

.edit-button {
	top: 12px;
	right: 4px;
	position: absolute;
}

.scrollable-dialog-container {
	padding-top: 24px;
}

.buttons-container {
	position: relative;
	padding: 0 56px 0 16px;
	margin: 24px 0 10px 0;
}

.download-button {
	position: absolute !important;
	top: -6px;
	right: 4px;
}

.contribution-grid {
	min-height: calc(100vh - 120px - 48px - 4px);
}

.login {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	background: #964c59;
	background-image: url("./images/wtxw4ai78vn_r92ukz30ef342.jpg");
	background-size: cover;
	background-position: center;
}

@keyframes showWrapper {
	from {
		transform: scale(0.7);
	}
	to {
		transform: scale(1);
	}
}
.login .wrapper {
	width: 100%;
	max-width: 400px;
	margin: auto;
	animation: showWrapper 0.2s forwards;
}
.login .wrapper img {
	max-width: 100px;
}
.login .wrapper .outside {
	height: 50px;
	margin: 40px auto;
	text-align: center;
}

.getty-images {
	width: 100%;
	background: #eaeaea;
	min-height: 100vh;
	padding: 10vh 0;
}
.getty-images .search {
	width: 100%;
	max-width: 650px;
	margin: auto;
	box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	background: #fff;
}
.getty-images .search input {
	width: 100%;
	font-size: 16px;
	padding: 16px 25px;
	outline: 0;
	border: 0;
	background: transparent;
}
.getty-images .results {
	width: 100%;
	max-width: 1000px;
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	padding: 5vh 15px;
}
.getty-images .results .result {
	width: 20%;
	padding-bottom: 20%;
	display: flex;
	position: relative;
}
.getty-images .results .result .wrapper {
	width: 100%;
	height: 100%;
	position: absolute;
	padding: 4px;
}
@keyframes showImage {
	to {
		transform: scale(1);
		opacity: 1;
	}
}
.getty-images .results .result .wrapper .image {
	width: 100%;
	height: 100%;
	background-color: #dadada;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	transform: scale(0.6);
	opacity: 0;
	animation: showImage 0.5s forwards;
}
.getty-images .pagination {
	width: 100%;
	max-width: 250px;
	display: flex;
	justify-content: space-around;
	margin: auto;
}

.indicator {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 10px 0;
}
